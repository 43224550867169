import React from 'react';
import { FormattedHTMLMessage, Link, useIntl } from 'gatsby-plugin-intl';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Container from '../Container';
import Licensed from '../../svgs/home/licensed.svg';
import Tech from '../../svgs/home/tech.svg';
import Trusted from '../../svgs/home/trusted.svg';
import ScrollContainer from 'react-indiana-drag-scroll';

// 【LOGO】
const ICONS = {
  licensed: <Licensed height={42} />,
  tech: <Tech height={42} />,
  trusted: <Trusted height={42} />,
};

const WhyItem = ({ name, mobileScroll = false, namePrePath = '' }) => {
  const mobileScrollStyle = {
    box: `
    box-shadow:0px 3px 60px 1px rgba(233, 233, 233, 0.8);
    flex: 0 0 auto;
    margin-right:22px;
    width: 220px;
    height: 281px;
    padding-top: 33px;
    margin-top: 0px;
    :first-of-type {
      margin-top: 0;
    }
  `,
    title: `
    font-size: 18px;
    line-height: 1.11;
  `,
    text: `font-size: 14px;
    line-height: 19px;
    ${Tablet}{
      line-height: 20px;
    }
    color: #161616;
    margin-top: 16px;
    width: 176px;`,
  };
  const notMobileScrollStyle = {
    box: ` 
    box-shadow:0 3px 10px 2px rgba(0, 0, 0, 0.05);
    width: 324px;
    height: 196px;
    padding-top: 18px;
    margin-top: 24px;
    :first-of-type {
      margin-top: 20px;
    }`,
    title: `font-size: 16px;
    line-height: 1.5;`,
    text: `font-size: 14px;
    width: 288px;
    // line-height: 1.43;
    ${Tablet}{
      // line-height: 1.71;
    }
    margin-top: 10px;`,
  };
  const intl = useIntl();
  return (
    <div
      css={css`
        background-color: #ffffff;

        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        ${mobileScroll ? mobileScrollStyle.box : notMobileScrollStyle.box};

        ${Tablet} {
          margin-right: 0;
          width: 220px;
          height: 281px;
          padding-top: 33px;
          margin-top: 0px;
          :first-of-type {
            margin-top: 0;
          }
        }
        ${Desktop} {
          width: 352px;
          height: 241px;
          padding-top: 33px;
        }
      `}
    >
      <img
        height={42}
        src={intl.formatMessage({ id: `${namePrePath}.${name}.icon` })}
      ></img>

      <div
        css={css`
          margin-top: 12px;
          font-weight: 500;
          ${mobileScroll
            ? mobileScrollStyle.title
            : notMobileScrollStyle.title};
          ${Tablet} {
            margin-top: 12px;
            font-size: 18px;
            line-height: 1.11;
          }
          ${Desktop} {
            font-size: 18px;
            line-height: 1.33;
            margin-top: 12px;
          }
        `}
      >
        <FormattedHTMLMessage id={`${namePrePath}.${name}.title`} />
      </div>
      <div
        css={css`
          font-weight: normal;
          ${mobileScroll ? mobileScrollStyle.text : notMobileScrollStyle.text};
          margin-top: 10px;
          ${Tablet} {
            font-size: 14px;
            color: #161616;
            width: 176px;
          }
          ${Desktop} {
            font-size: 14px;
            color: #161616;
            width: 270px;
          }
        `}
      >
        <FormattedHTMLMessage id={`${namePrePath}.${name}.text`} />
      </div>
    </div>
  );
};
const HomeWhy = ({
  titleStyle,
  mobileScroll = false,
  nameList,
  namePrePath,
  title,
  ...style
}) => {
  return (
    <>
      <div
        css={css`
          width: 100%;
          ${Tablet} {
            display: flex;
            justify-content: center;
            width: 100%;
          }
          ${Desktop} {
            display: flex;
            justify-content: center;
            width: 100%;
          }
        `}
        {...style}
      >
        <Container
          css={css`
            padding: 0;
            ${Tablet} {
              margin-top: 76px;
              padding: 0 34px;
            }
            ${Desktop} {
              margin-top: 0;
              padding: 0 20px;
            }
          `}
        >
          {/* title */}
          <div css={titleStyle}>{title}</div>
          {/* 【卡片部分】 */}
          {/* web、ipad端 */}
          <div
            css={css`
              display: none;
              ${Tablet} {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
              }
              ${Desktop} {
                display: flex;
                justify-content: space-between;
                margin-top: 37px;
              }
            `}
          >
            <WhyItem
              name={nameList[0]}
              mobileScroll={mobileScroll}
              namePrePath={namePrePath}
            />
            <WhyItem
              name={nameList[1]}
              mobileScroll={mobileScroll}
              namePrePath={namePrePath}
            />
            <WhyItem
              name={nameList[2]}
              mobileScroll={mobileScroll}
              namePrePath={namePrePath}
            />
          </div>
          {/* mobile端 */}
          {!mobileScroll && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                ${Tablet} {
                  display: none;
                }
              `}
            >
              <WhyItem name={nameList[0]} namePrePath={namePrePath} />
              <WhyItem name={nameList[1]} namePrePath={namePrePath} />
              <WhyItem name={nameList[2]} namePrePath={namePrePath} />
            </div>
          )}
          {mobileScroll && (
            <ScrollContainer
              css={css`
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-top: -30px;
                padding: 40px 0px 40px 24px;
                ${Tablet} {
                  display: none;
                }
              `}
            >
              <WhyItem
                name={nameList[0]}
                mobileScroll
                namePrePath={namePrePath}
              />
              <WhyItem
                name={nameList[1]}
                mobileScroll
                namePrePath={namePrePath}
              />
              <WhyItem
                name={nameList[2]}
                mobileScroll
                namePrePath={namePrePath}
              />
            </ScrollContainer>
          )}
        </Container>
      </div>
    </>
  );
};
export default HomeWhy;
